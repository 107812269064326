var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.displayMap.showSignIn
        ? _c("sign-in", { on: { authState: _vm.authStateChange } })
        : _vm._e(),
      _vm.displayMap.requireNewPassword
        ? _c("require-new-password", {
            attrs: { data: _vm.userData },
            on: { authState: _vm.authStateChange }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }