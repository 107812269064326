var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-layout-login-wraper" },
    [_c("authenticator", { on: { signedIn: _vm.loginSuccess } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }