//
//
//
//
//
//

import { mapActions, mapMutations } from 'vuex';
import Authenticator from './component/Authenticator';
export default {
  data() {
    return {
      loadding: false
    };
  },
  components: {
    Authenticator
  },
  methods: {
    ...mapActions('user', ['Login', 'Logout']),
    ...mapMutations('user', {
      saveToken: 'SET_TOKEN'
    }),

    async loginSuccess(res) {
      await this.saveToken(res);
      this.$router.push({ path: '/', query: { ...this.$route.query } });
    }
  }
};
