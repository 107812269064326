//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    user() {
      return this.data.user;
    },
    token() {
      return this.data.id;
    }
  },
  data() {
    return {
      loadding: false,
      password: null,
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      form: this.$form.createForm(this, { name: 'user_update' })
    };
  },
  methods: {
    ...mapMutations('user', {
      saveToken: 'SET_TOKEN'
    }),
    // validatePassword(rule, value, callback) {
    //   if (value) {
    //     const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    //     const contains_number = /\d/.test(value);
    //     const contains_uppercase = /[A-Z]/.test(value);
    //     // const contains_special_character = format.test(value);
    //     if (
    //       // this.contains_eight_characters === true &&
    //       // contains_special_character === true &&
    //       contains_uppercase === true &&
    //       contains_number === true &&
    //       value.length >= 8
    //     ) {
    //       callback();
    //     } else {
    //       callback(this.$t("Password at least number, uppercase and lowercase letter, and at least 8"));
    //     }
    //   }
    // },
    checkPassword({ target }) {
      this.password = target.value;
      this.password_length = this.password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length > 7) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    handlePasswordCheck(rule, value, callback) {
      const password = this.form.getFieldValue('newPassword');
      if (value === undefined) {
        callback(new Error(this.$tc('Please enter your password')));
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error(this.$tc('Two passwords are inconsistent')));
      }
      callback();
    },

    handleSubmit() {
      const {
        form: { validateFields }
      } = this;

      validateFields(async (err, values) => {
        this.loadding = true;
        try {
          if (!err) {
            const res = await this.$s.api.user.completeNewPassword(
              {
                email: values.email,
                newPassword: values.newPassword
              },
              this.token
            );

            await this.saveToken(res);
            this.$router.push({ path: '/' });
          }
        } catch (error) {
          console.log(error);
        }
        this.loadding = false;
      });
    }
  }
};
