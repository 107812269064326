//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      loadding: false,
      form: this.$form.createForm(this, { name: 'user_login' })
    };
  },
  methods: {
    handleSubmit() {
      const {
        form: { validateFields }
      } = this;

      validateFields(async (err, values) => {
        this.loadding = true;
        try {
          if (!err) {
            const data = await this.$s.api.user.login(values);
            const { user } = data;
            if (user.firstTime) {
              this.$emit('authState', { state: 'requireNewPassword', data });
            } else {
              this.$emit('authState', { state: 'signedIn', data });
            }
          }
        } catch (error) {
          console.log(error);
          this.$message.error(this.$tc('Login failed'), 3, () => {});
        }
        this.loadding = false;
      });
    },
    forgot() {
      this.$router.push({ path: '/user/forgot', query: { ...this.$route.query } });
    },
    signUp() {
      this.$router.push({ path: '/user/request', query: { ...this.$route.query } });
    }
  }
};
