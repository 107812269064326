var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "text-h6 q-my-md" }, [
        _vm._v(" " + _vm._s(_vm.$t("Please update your password")) + " ")
      ]),
      _c(
        "a-form",
        { staticClass: "user-layout-login", attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            [
              _c(
                "a-input",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "email",
                        {
                          rules: [
                            {
                              required: true,
                              message: _vm.$t("Please input your E-mail!")
                            },
                            {
                              type: "email",
                              message: _vm.$t("The input is not valid E-mail!")
                            }
                          ],
                          initialValue: _vm.user.email
                        }
                      ],
                      expression:
                        "[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('Please input your E-mail!')\n              },\n              {\n                type: 'email',\n                message: $t('The input is not valid E-mail!')\n              }\n            ],\n            initialValue: user.email\n          }\n        ]"
                    }
                  ],
                  attrs: {
                    disabled: "",
                    autocomplete: "new-password",
                    type: "text",
                    placeholder: _vm.$t("Enter your email")
                  }
                },
                [
                  _c("a-icon", {
                    style: { color: "rgba(0,0,0,.25)" },
                    attrs: { slot: "prefix", type: "user" },
                    slot: "prefix"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-popover",
                { attrs: { placement: "right" } },
                [
                  _c("template", { slot: "content" }, [
                    _c("div", { staticClass: "input_container" }, [
                      _c("ul", [
                        _c(
                          "li",
                          {
                            class: { is_valid: _vm.contains_eight_characters }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("At least 8 characters")) +
                                " "
                            )
                          ]
                        ),
                        _c("li", { class: { is_valid: _vm.contains_number } }, [
                          _vm._v(_vm._s(_vm.$t("Include number")))
                        ]),
                        _c(
                          "li",
                          { class: { is_valid: _vm.contains_uppercase } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Include upper character")) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "checkmark_container",
                          class: { show_checkmark: _vm.valid_password }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "50%",
                                height: "50%",
                                viewBox: "0 0 140 100"
                              }
                            },
                            [
                              _c("path", {
                                staticClass: "checkmark",
                                class: { checked: _vm.valid_password },
                                attrs: { d: "M10,50 l25,40 l95,-70" }
                              })
                            ]
                          )
                        ]
                      )
                    ])
                  ]),
                  _c(
                    "a-input-password",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "newPassword",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$t(
                                    "Password length at least 8 characters"
                                  )
                                },
                                {
                                  min: 8,
                                  message: _vm.$t(
                                    "Password length at least 8 characters"
                                  )
                                }
                              ],
                              validateTrigger: ["change", "blur"]
                            }
                          ],
                          expression:
                            "[\n            'newPassword',\n            {\n              rules: [\n                { required: true, message: $t('Password length at least 8 characters') },\n                { min: 8, message: $t('Password length at least 8 characters') }\n              ],\n              validateTrigger: ['change', 'blur']\n            }\n          ]"
                        }
                      ],
                      attrs: { placeholder: _vm.$t("New Password") },
                      on: { change: _vm.checkPassword }
                    },
                    [
                      _c("a-icon", {
                        style: { color: "rgba(0,0,0,.25)" },
                        attrs: { slot: "prefix", type: "lock" },
                        slot: "prefix"
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-input-password",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "password2",
                        {
                          rules: [
                            {
                              validator: _vm.handlePasswordCheck
                            }
                          ],
                          validateFirst: true
                        }
                      ],
                      expression:
                        "[\n          'password2',\n          {\n            rules: [\n              {\n                validator: handlePasswordCheck\n              }\n            ],\n            validateFirst: true\n          }\n        ]"
                    }
                  ],
                  attrs: {
                    autocomplete: "new-password",
                    placeholder: _vm.$t("Confirm password")
                  }
                },
                [
                  _c("a-icon", {
                    style: { color: "rgba(0,0,0,.25)" },
                    attrs: { slot: "prefix", type: "lock" },
                    slot: "prefix"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    "html-type": "submit",
                    block: "",
                    loading: _vm.loadding
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.handleSubmit($event)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }