//
//
//
//
//
//
//
//
//
//
//

import SignIn from './SignIn.vue';
import RequireNewPassword from './RequireNewPassword.vue';

export default {
  name: 'Authenticator',
  components: {
    SignIn,
    RequireNewPassword
  },
  data() {
    return {
      userData: {},
      displayMap: {}
    };
  },
  mounted() {
    this.displayMap = this.updateDisplayMap('signedOut');
  },
  methods: {
    authStateChange({ state, data }) {
      if (state === 'signedIn') {
        this.$emit('signedIn', data);
      } else {
        this.userData = data;
        this.displayMap = this.updateDisplayMap(state);
      }
    },

    updateDisplayMap: state => {
      return {
        showSignIn: state === 'signedOut' || state === 'signIn',
        requireNewPassword: state === 'requireNewPassword'
      };
    }
  }
};
