var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "text-h6 mb-xs" }, [
        _vm._v(" " + _vm._s(_vm.$t("Sign In")) + " ")
      ]),
      _c("div", { staticClass: "mb-xs" }, [
        _vm._v(" " + _vm._s(_vm.$t("Don’t have account?")) + " "),
        _c("a", { on: { click: _vm.signUp } }, [
          _vm._v(_vm._s(_vm.$t("Registration")))
        ])
      ]),
      _c(
        "a-form",
        {
          ref: "formLogin",
          staticClass: "user-layout-login",
          attrs: { id: "formLogin", form: _vm.form }
        },
        [
          _c(
            "a-form-item",
            [
              _c(
                "a-input",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "email",
                        {
                          rules: [
                            {
                              required: true,
                              message: _vm.$t("Please input your E-mail!")
                            },
                            {
                              type: "email",
                              message: _vm.$t("The input is not valid E-mail!")
                            }
                          ]
                        }
                      ],
                      expression:
                        "[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('Please input your E-mail!')\n              },\n              {\n                type: 'email',\n                message: $t('The input is not valid E-mail!')\n              }\n            ]\n          }\n        ]"
                    }
                  ],
                  attrs: {
                    autocomplete: "username",
                    placeholder: _vm.$t("Enter your email")
                  }
                },
                [
                  _c("a-icon", {
                    style: { color: "rgba(0,0,0,.25)" },
                    attrs: { slot: "prefix", type: "user" },
                    slot: "prefix"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-input-password",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "password",
                        {
                          rules: [
                            {
                              required: true,
                              message: _vm.$t("Please enter your password")
                            }
                          ],
                          validateTrigger: "blur"
                        }
                      ],
                      expression:
                        "[\n          'password',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('Please enter your password')\n              }\n            ],\n            validateTrigger: 'blur'\n          }\n        ]"
                    }
                  ],
                  attrs: {
                    autocomplete: "current-password",
                    placeholder: _vm.$t("Enter your password")
                  }
                },
                [
                  _c("a-icon", {
                    style: { color: "rgba(0,0,0,.25)" },
                    attrs: { slot: "prefix", type: "lock" },
                    slot: "prefix"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { staticClass: "mb-sm" },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    "html-type": "submit",
                    block: "",
                    loading: _vm.loadding
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.handleSubmit($event)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Sign In")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", [
        _vm._v(" " + _vm._s(_vm.$t("Forget your password?")) + " "),
        _c("a", { on: { click: _vm.forgot } }, [
          _vm._v(_vm._s(_vm.$t("Reset password?")))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }